import { Interpolation, Theme } from '@emotion/react';
import Link from 'next/link';
import React, { CSSProperties } from 'react';

import { pathForResource, PathResources } from '@chartsy/shared/utils';

type LinkProps = Parameters<typeof Link>[0];

type BaseResourceLinkProps = Omit<LinkProps, 'href'> & {
  className?: string;
  css?: Interpolation<Theme>;
  disableLink?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
};

interface ResourceLinkPropsWithTo extends BaseResourceLinkProps {
  href?: string;
  to: PathResources;
}

interface ResourceLinkPropsWithHref extends BaseResourceLinkProps {
  href: string;
  to?: PathResources;
}

export type ResourceLinkProps =
  | ResourceLinkPropsWithHref
  | ResourceLinkPropsWithTo;

const ResourceLink = (props: ResourceLinkProps) => {
  const {
    className,
    children,
    disableLink = false,
    style,
    href,
    to,
    ...rest
  } = props;

  if (disableLink) {
    return (
      <div className={className} style={style}>
        {children}
      </div>
    );
  }

  /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid */
  if (to) {
    return (
      <Link {...rest} as={undefined} href={pathForResource(to)} passHref>
        <a className={className} onClick={props.onClick} style={style}>
          {children}
        </a>
      </Link>
    );
  }

  if (!href) {
    throw new Error('Missing to or href');
  }

  return (
    <Link {...rest} as={undefined} href={href} passHref>
      <a className={className} onClick={props.onClick} style={style}>
        {children}
      </a>
    </Link>
  );
  /* eslint-enable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid */
};

export default ResourceLink;

import { Interpolation, Theme } from '@emotion/react';
import React from 'react';
import tw from 'twin.macro';

import ResourceLink, { ResourceLinkProps } from './ResourceLink';

type TextHues = 'danger' | 'gray' | 'purple' | 'blue';

type TextLinkProps = ResourceLinkProps & {
  hue?: TextHues;
};

const TEXT_CSS: {
  [key in TextHues]: Interpolation<Theme>;
} = {
  blue: tw`text-blue-700`,
  danger: tw`text-danger-800`,
  gray: tw`text-gray-800`,
  purple: tw`text-purple-700`,
};

// omit 'as' to fix typescript issue
const TextLink = ({ as, hue = 'gray', ...rest }: TextLinkProps) => (
  <ResourceLink css={TEXT_CSS[hue]} tw="hover:underline" {...rest} />
);

export default TextLink;

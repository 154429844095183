import { ComponentType, SVGProps } from 'react';

type SvgComponent = ComponentType<SVGProps<SVGSVGElement>>;

export type SvgComponents = {
  [key: string]: undefined | SvgComponent;
};

type RequireResult = { ReactComponent: SvgComponent };

export default function importAllSvgs(r: __WebpackModuleApi.RequireContext) {
  return r.keys().map((key) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const value = (r(key) as unknown) as RequireResult;
    return {
      name: key.replace('./', '').replace(/\.[^./]+$/, ''),
      ReactComponent: value.ReactComponent,
    };
  });
}

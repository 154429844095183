import { css, keyframes } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import 'twin.macro';

import { useBasket, BasketEmitter } from '../contexts/basket';
import IconBasket from './Icon/Basket';

const ANIMATION_DURATION = 1500;
const ADD_TO_BASKET_KEYFRAMES = keyframes`
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(2);
  }
  50% {
    transform: scale(1);
  }
  60% {
    transform: scale(1) rotate(-0.1turn);
  }
  80% {
    transform: scale(1) rotate(0.1turn);
  }
  100% {
    transform: scale(1);
  }
`;

const Basket = ({ className }: { className?: string }) => {
  const basket = useBasket();
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    let pid: number;
    const off = BasketEmitter.on('add', () => {
      setAnimate(true);
      pid = window.setTimeout(() => {
        setAnimate(false);
      }, ANIMATION_DURATION);
    });
    return () => {
      setAnimate(false);
      off();
      window.clearTimeout(pid);
    };
  }, []);

  return (
    <div className={className} tw="relative text-center">
      <IconBasket
        aria-label="Basket"
        css={
          animate &&
          css`
            animation: ${ADD_TO_BASKET_KEYFRAMES} 1.5s ease-in-out 1;
          `
        }
        tw="h-7 w-7 md:h-8 md:w-8"
      />
      {basket.items.length === 0 ? null : (
        <span tw="flex absolute bottom-0 right-0 w-3 h-3 -mb-0.5 bg-pink-500 rounded-full shadow" />
      )}
    </div>
  );
};

export default Basket;

import { keyframes } from '@emotion/react';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import tw, { css, theme } from 'twin.macro';

import { FEATURED_LINKS } from '@chartsy/shared/data';
import {
  pathForResource,
  siteSectionForPath,
  SiteSections,
} from '@chartsy/shared/utils';

import { useAuth } from '../contexts/auth';
import Basket from './Basket';
import HeaderLink from './HeaderLink';
import IconClose from './Icon/Close';
import IconMenu from './Icon/Menu';
import { ReactComponent as IconSearch } from './Icon/search.svg';
import Logo from './Logo';
import ResourceLink from './ResourceLink';
import { ACCOUNT_GRADIENT_CSS, STORE_GRADIENT_CSS, Container } from './Styled';
import TagIcon from './Tag/Icon';

const SubHeaderLink = tw(
  ResourceLink,
)`flex-none py-2 my-2 text-purple-500 rounded-xl inline-block md:text-lg can-hover:hover:underline focus:underline`;

export interface HeaderProps {
  hideLinks?: boolean;
  background?: 'light' | 'lightest';
  linkLogo?: boolean;
}

const MENU_APPEAR_KEYFRAMES = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const Header = ({
  background,
  hideLinks = false,
  linkLogo = true,
}: HeaderProps) => {
  const auth = useAuth();
  const router = useRouter();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleHeaderLinkClick = useCallback(() => setIsMenuOpen(false), []);
  const siteSection = siteSectionForPath(router.pathname);
  const backgroundOverride =
    background === 'lightest'
      ? tw`backgroundColor[rgba(255,255,255,0.2)]`
      : background === 'light'
      ? tw`backgroundColor[rgba(255,255,255,0.8)]`
      : null;

  const headerLinks = (
    <>
      <HeaderLink
        isSelected={siteSection === SiteSections.Store}
        onClick={handleHeaderLinkClick}
        siteSection={siteSection}
        to="home"
      >
        Store
      </HeaderLink>
      <HeaderLink
        isSelected={siteSection === SiteSections.Learn}
        onClick={handleHeaderLinkClick}
        siteSection={siteSection}
        to="learn"
      >
        Learn
      </HeaderLink>
      {auth.user ? (
        <HeaderLink
          isSelected={siteSection === SiteSections.Account}
          onClick={handleHeaderLinkClick}
          siteSection={siteSection}
          to="account"
        >
          Your Chartsy
        </HeaderLink>
      ) : (
        <HeaderLink
          onClick={handleHeaderLinkClick}
          siteSection={siteSection}
          to={{ intent: pathForResource('account'), type: 'signInIntent' }}
        >
          Your Chartsy
        </HeaderLink>
      )}
    </>
  );

  const iconCss =
    siteSection === SiteSections.Account
      ? tw`text-blue-500`
      : tw`text-purple-500`;

  return (
    <header>
      <div
        css={
          backgroundOverride ||
          (siteSection === SiteSections.Account
            ? ACCOUNT_GRADIENT_CSS
            : STORE_GRADIENT_CSS)
        }
        tw="relative z-10"
      >
        <Container>
          <div tw="grid gridTemplateColumns[1fr auto 1fr] items-center">
            {hideLinks ? (
              <div />
            ) : (
              <div>
                <button
                  onClick={() => setIsMenuOpen((current) => !current)}
                  tw="flex-none md:hidden py-3 pr-2 md:py-4"
                  type="button"
                >
                  {isMenuOpen ? (
                    <IconClose css={iconCss} tw="h-5 w-5" />
                  ) : (
                    <IconMenu css={iconCss} tw="h-5 w-5" />
                  )}
                </button>

                <div tw="hidden md:flex w-full">
                  <div tw="flex md:space-x-4 lg:space-x-8 items-center">
                    {headerLinks}
                  </div>
                </div>
              </div>
            )}
            {linkLogo ? (
              <ResourceLink
                to={siteSection === SiteSections.Account ? 'account' : 'home'}
              >
                <Logo
                  color={siteSection === SiteSections.Account ? 'blue' : 'pink'}
                  tw="my-3 md:my-2"
                />
              </ResourceLink>
            ) : (
              <Logo
                color={siteSection === SiteSections.Account ? 'blue' : 'pink'}
                tw="my-3 md:my-2"
              />
            )}
            {!hideLinks &&
              ([SiteSections.Store, SiteSections.Learn].includes(
                siteSection,
              ) ? (
                <ResourceLink to="checkout" tw="flex-none pl-3 py-3 ml-auto">
                  <Basket css={iconCss} />
                </ResourceLink>
              ) : (
                <HeaderLink
                  siteSection={siteSection}
                  to="home"
                  tw="ml-auto md:hidden"
                >
                  Shop
                </HeaderLink>
              ))}
          </div>
        </Container>
      </div>
      {isMenuOpen && (
        <div
          css={[
            css`
              animation: ${MENU_APPEAR_KEYFRAMES} 0.3s ease-in-out 1;
            `,
            backgroundOverride ||
              (siteSection === SiteSections.Account
                ? tw`bg-blue-50`
                : tw`bg-purple-50`),
          ]}
          tw="flex justify-center space-x-4 py-4 px-2 md:hidden relative z-10"
        >
          {headerLinks}
        </div>
      )}
      {!hideLinks &&
        SiteSections.Store === siteSection &&
        router.pathname !== '/' && (
          <div
            css={backgroundOverride || tw`bg-purple-50 bg-opacity-50`}
            tw="relative z-10"
          >
            <Container tw="flex md:justify-center overflow-y-auto space-x-4 md:space-x-8 lg:space-x-12">
              {FEATURED_LINKS.map(({ title, to }) => (
                <SubHeaderLink key={title} to={to} tw="flex items-center">
                  {typeof to !== 'string' && to.type === 'tag' && (
                    <TagIcon
                      color={theme`colors.purple-400`}
                      slug={to.tagSlug}
                      tw="h-4 w-4 mr-2"
                    />
                  )}
                  <span>{title}</span>
                </SubHeaderLink>
              ))}
              {/* TODO link to search */}
              <div tw="flex items-center bg-purple-100 text-purple-400 my-2 px-2 rounded-lg">
                <IconSearch tw="h-4 w-4" />
                <span tw="ml-2 pr-6 lg:pr-8">Search…</span>
              </div>
            </Container>
          </div>
        )}
    </header>
  );
};

export default Header;

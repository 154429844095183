import tw, { styled } from 'twin.macro';

import { SiteSections } from '@chartsy/shared/utils';

import ResourceLink from './ResourceLink';

const HeaderLink = styled(
  ResourceLink,
)(
  ({
    isSelected = false,
    siteSection,
  }: {
    isSelected?: boolean;
    siteSection: SiteSections;
  }) => [
    tw`px-3 inline-block rounded-full font-bold md:text-lg can-hover:hover:bg-opacity-90 focus:(outline-none ring-2)`,
    siteSection === SiteSections.Account
      ? tw`ring-blue-400`
      : tw`ring-purple-400`,
    isSelected
      ? siteSection === SiteSections.Account
        ? tw`bg-blue-500 can-hover:hover:bg-blue-600`
        : tw`bg-purple-500 can-hover:hover:bg-purple-600`
      : siteSection === SiteSections.Account
      ? tw`text-blue-500 can-hover:hover:text-blue-600`
      : tw`text-purple-500 can-hover:hover:text-purple-600`,
    isSelected
      ? tw`text-white can-hover:hover:text-opacity-90`
      : tw`bg-transparent`,
  ],
);

export default HeaderLink;

import React from 'react';
import 'twin.macro';

import ICONS from './icons';

interface TagIconProps {
  className?: string;
  color: string;
  slug: string;
}

const TagIcon = ({ className, color, slug }: TagIconProps) => {
  const Component = ICONS[slug.toLowerCase()];

  return Component ? (
    <Component css={{ color }} className={className} tw="fill-current" />
  ) : (
    <div className={className} />
  );
};

export default TagIcon;

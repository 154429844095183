import React from 'react';

function IconMenu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 1.76a.75.75 0 01.75-.749h14.5a.75.75 0 110 1.499H1.75A.75.75 0 011 1.76zM1 9.011a.75.75 0 01.75-.75h14.5a.75.75 0 110 1.5H1.75A.75.75 0 011 9.01zM1 16.262a.75.75 0 01.75-.75h14.5a.75.75 0 110 1.5H1.75a.75.75 0 01-.75-.75z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default IconMenu;

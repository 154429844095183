import { PaymentRequest } from '@stripe/stripe-js';
import { useMachine } from '@xstate/react';
import isEqual from 'lodash/isEqual';
import { createNanoEvents } from 'nanoevents';
import { useRouter } from 'next/router';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { pathForResource } from '@chartsy/shared/utils';

import { StorageKeys } from '../constants';
import basketMachine, {
  BasketItem,
  BasketContext,
  basketItemsAreEqual,
} from '../machines/basket';
import createTypeSafeContext from '../utils/createTypeSafeContext';
import useLazyStorageState from '../utils/useLazyStorageState';
import { useAuth } from './auth';

export const BasketEmitter = createNanoEvents<BasketEmittedEvents>();

interface BasketEmittedEvents {
  add: () => void;
}

export interface Basket {
  add: (item: BasketItem) => void;
  canModify: boolean;
  errorMessage?: string;
  has: (id: string) => boolean;
  hasUnrecoverableError: boolean;
  items: BasketItem[];
  paymentIntent: BasketContext['paymentIntent'];
  paymentRequest?: PaymentRequest;
  prevStorePath: string;
  remove: (id: string) => void;
  reset: () => void;
  totalPrice: number;
  warningMessage?: string;
}

const [useBasket, BasketProvider] = createTypeSafeContext<Basket>();
export { useBasket };

const NON_STORE_PATHS = new Set([
  pathForResource('account'),
  pathForResource('checkout'),
  pathForResource('signIn'),
]);

function useProvideBasket(): Basket {
  const router = useRouter();
  const { user } = useAuth();
  const [prevStorePath, setPrevStorePath] = useState(pathForResource('home'));
  const [storedItems, setStoredItems] = useLazyStorageState<BasketItem[]>(
    StorageKeys.BasketItems,
    [],
  );

  const hasLoadedStoredItems = useRef(false);
  const [current, send, service] = useMachine(basketMachine, {
    context: {
      userHasInteracted: router.pathname === pathForResource('checkout'),
      items: storedItems,
      user,
    },
  });

  useEffect(() => {
    if (user && !service.state.context.user) {
      send({ type: 'SIGNED_IN', user });
    }
  }, [service, send, user]);

  useEffect(() => {
    // Items are loaded after first render, so the machine needs to be updated
    if (storedItems.length > 0 && !hasLoadedStoredItems.current) {
      hasLoadedStoredItems.current = true;
      if (!basketItemsAreEqual(storedItems, current.context.items)) {
        send({ items: storedItems, type: 'SET_ITEMS' });
      }
    }
  }, [send, storedItems, current.context.items]);

  useEffect(() => {
    const handleChange = (context: BasketContext) => {
      if (!isEqual(storedItems, context.items)) {
        setStoredItems(context.items);
      }
    };
    service.onChange(handleChange);
    return () => {
      service.off(handleChange);
    };
  }, [service, storedItems, setStoredItems]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (url === pathForResource('checkout')) {
        if (!NON_STORE_PATHS.has(window.location.pathname)) {
          setPrevStorePath(window.location.pathname);
        }
        if (!current.context.userHasInteracted) {
          send('USER_HAS_INTERACTED');
        }
        send('CLEAR_MESSAGES');
      }
    };

    router.events.on('beforeHistoryChange', handleRouteChange);

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
    };
  });

  return {
    add: (item: BasketItem) => {
      send({ item, type: 'ADD_ITEM' });
      BasketEmitter.emit('add');
    },
    canModify: current.matches('idle') || current.matches('handlePromises'),
    errorMessage: current.context.errorMessage,
    has: (id: Parameters<Basket['has']>[0]) =>
      !!current.context.items.find((item) => item.id === id),
    hasUnrecoverableError: current.matches('error'),
    items: current.context.items,
    paymentIntent: current.context.paymentIntent,
    paymentRequest: current.context.paymentRequest,
    prevStorePath,
    remove: (id: string) => {
      send({ id, type: 'REMOVE_ITEM' });
    },
    reset: () => {
      send('RESET');
    },
    totalPrice: current.context.items.reduce(
      (result, { price }) => result + price,
      0,
    ),
    warningMessage: current.context.warningMessage,
  };
}

export const ProvideBasket = ({ children }: { children?: ReactNode }) => {
  const basket = useProvideBasket();

  return <BasketProvider value={basket}>{children}</BasketProvider>;
};

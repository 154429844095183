import React from 'react';

function IconBasket(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.716 10.766H18.284c.26.008.514.07.748.185.233.115.44.279.603.484a1.647 1.647 0 01.305 1.476l-1.47 5.784c-.09.378-.311.706-.614.939a1.79 1.79 0 01-1.058.366l-.013-.752V20H7.202a1.79 1.79 0 01-1.058-.366 1.673 1.673 0 01-.613-.939L4.06 12.912a1.646 1.646 0 01.305-1.477c.163-.205.37-.37.603-.484.234-.114.489-.177.748-.185zm11.06 7.731a.286.286 0 00.165-.056.172.172 0 00.066-.094l.004-.016 1.475-5.798.005-.02a.144.144 0 00.003-.07.174.174 0 00-.035-.073.257.257 0 00-.09-.07.327.327 0 00-.124-.032H5.755a.327.327 0 00-.125.032.257.257 0 00-.089.07.175.175 0 00-.035.074.143.143 0 00.003.068l.005.02 1.475 5.8.004.015a.171.171 0 00.066.094c.04.032.098.054.164.056h9.554z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.895 12.698c.415 0 .752.337.752.752v3.866a.752.752 0 01-1.503 0V13.45c0-.415.336-.752.751-.752zM12 12.698c.415 0 .752.337.752.752v3.866a.752.752 0 01-1.504 0V13.45c0-.415.337-.752.752-.752zM15.105 12.698c.415 0 .751.337.751.752v3.866a.751.751 0 11-1.503 0V13.45c0-.415.337-.752.752-.752zM10.65 5.346a.751.751 0 01-.505.935c-.82.244-1.542.7-2.084 1.308a3.982 3.982 0 00-.986 2.11.751.751 0 01-1.49-.203A5.485 5.485 0 016.94 6.589 6.017 6.017 0 019.716 4.84a.751.751 0 01.934.505zM13.35 5.346a.752.752 0 01.934-.505A6.012 6.012 0 0117.07 6.6a5.478 5.478 0 011.347 2.925.751.751 0 11-1.49.194 3.976 3.976 0 00-.981-2.122 4.51 4.51 0 00-2.09-1.316.751.751 0 01-.507-.935z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.179 5.718c0-.949.769-1.718 1.718-1.718h2.206a1.718 1.718 0 010 3.436h-2.206c-.949 0-1.718-.77-1.718-1.718zm1.718-.215a.215.215 0 100 .43h2.206a.215.215 0 000-.43h-2.206z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconBasket;

import { ApolloQueryResult } from '@apollo/client';
import { PaymentRequest } from '@stripe/stripe-js';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import { toast } from 'react-hot-toast';
import {
  Machine,
  assign,
  DoneInvokeEvent,
  send,
  TransitionsConfig,
} from 'xstate';

import { ErrorCodes, AlreadyOwnedError } from '@chartsy/shared';

import { createPaymentIntent, cancelPaymentIntent } from '../api';
import ApiError from '../api/error';
import apolloClient from '../apolloClient';
import { ShopUser } from '../contexts/auth';
import {
  ProductInput,
  CreatePaymentIntentResponse,
  IncompleteOrderQuery,
  BasketProductsForIdsQuery,
  BasketProductFragment,
  BasketProductsForIdsDocument,
  IncompleteOrderDocument,
} from '../generatedGraphql';
import pluralize from '../utils/pluralize';
import stripePromise from '../utils/stripePromise';

export type BasketItem = ProductInput;

export function basketItemsAreEqual(
  items1: BasketItem[],
  items2: BasketItem[],
): boolean {
  return (
    items1.length === items2.length &&
    items1.every((item1) => {
      const item2 = items2.find(({ id }) => id === item1.id);
      return isEqual(item1, item2);
    })
  );
}

function normalizeBasketItem({ id, currency, price }: BasketItem) {
  // Avoid storing the basket item if anything is missing, even somehow at
  // runtime
  if (!id || !currency || typeof price !== 'number') {
    throw new Error('Missing property from basket item');
  }
  return { id, currency, price };
}

type CreatePaymentIntentSuccess = CreatePaymentIntentResponse & {
  success: true;
};

type CreatePaymentIntentError =
  | {
      errorCode: ErrorCodes.AlreadyOwned;
      purchasedProductIds: string[];
      success: false;
    }
  | {
      errorCode: ErrorCodes.InvalidProduct;
      success: false;
    };

type CreatePaymentIntentOrError =
  | CreatePaymentIntentSuccess
  | CreatePaymentIntentError;

export interface BasketContext {
  userHasInteracted: boolean;
  errorEvent?: string;
  errorMessage?: string;
  items: BasketItem[];
  paymentIntentItems?: BasketItem[];
  paymentIntent?: CreatePaymentIntentResponse;
  paymentIntentPromise?: Promise<CreatePaymentIntentOrError>;
  paymentRequest?: PaymentRequest;
  paymentRequestPromise?: Promise<PaymentRequest | null>;
  shouldOverwriteFromServer: boolean;
  user?: ShopUser;
  warningMessage?: string;
}

interface BasketSchema {
  states: {
    checkingForAuth: Record<string, unknown>;
    fetchingIncompleteOrder: Record<string, unknown>;
    beforeCreatePaymentPromises: Record<string, unknown>;
    createPaymentPromises: Record<string, unknown>;
    handlePromises: {
      states: {
        paymentIntent: {
          states: {
            handlePromise: Record<string, unknown>;
            idle: Record<string, unknown>;
            checkError: Record<string, unknown>;
          };
        };
        paymentRequest: {
          states: {
            handlePromise: Record<string, unknown>;
            idle: Record<string, unknown>;
          };
        };
      };
    };
    idle: Record<string, unknown>;
    updatingItems: Record<string, unknown>;
    error: Record<string, unknown>;
  };
}

interface AddItemEvent {
  item: BasketItem;
  type: 'ADD_ITEM';
}

interface SetItemsEvent {
  items: BasketItem[];
  type: 'SET_ITEMS';
}

interface RemoveItemEvent {
  id: string;
  type: 'REMOVE_ITEM';
}

interface ResetEvent {
  type: 'RESET';
}

interface ErrorEvent {
  type: 'ERROR';
}

interface SuccessEvent {
  type: 'SUCCESS';
}

interface RetryEvent {
  type: 'RETRY';
}

interface UpdateItemsEvent {
  type: 'UPDATE_ITEMS';
}

interface ClearMessagesEvents {
  type: 'CLEAR_MESSAGES';
}

interface SignedInEvent {
  user: ShopUser;
  type: 'SIGNED_IN';
}

interface UserHasInteractedEvent {
  type: 'USER_HAS_INTERACTED';
}

type BasketEvents =
  | AddItemEvent
  | ClearMessagesEvents
  | ErrorEvent
  | RemoveItemEvent
  | ResetEvent
  | RetryEvent
  | SetItemsEvent
  | SignedInEvent
  | SuccessEvent
  | UpdateItemsEvent
  | UserHasInteractedEvent;

const COMMON_TRANSITIONS: TransitionsConfig<BasketContext, BasketEvents> = {
  ADD_ITEM: {
    target: 'beforeCreatePaymentPromises',
    actions: assign<BasketContext, AddItemEvent>({
      userHasInteracted: true,
      items: ({ items }, event) =>
        uniqBy([...items, normalizeBasketItem(event.item)], 'id'),
    }),
  },
  REMOVE_ITEM: {
    target: 'beforeCreatePaymentPromises',
    actions: [
      assign<BasketContext, RemoveItemEvent>({
        userHasInteracted: true,
        items: ({ items }, { id }) => items.filter((item) => item.id !== id),
      }),
      ({ items, user }: BasketContext) =>
        items.length === 0 && user && cancelPaymentIntent(),
    ],
  },
  RESET: {
    target: 'beforeCreatePaymentPromises',
    // Workaround for https://github.com/davidkpiano/xstate/issues/1198
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    actions: assign<BasketContext, any>({
      items: [],
    }),
  },
  SET_ITEMS: {
    target: 'beforeCreatePaymentPromises',
    actions: assign<BasketContext, SetItemsEvent>({
      items(_context, event) {
        return event.items;
      },
    }),
  },
  CLEAR_MESSAGES: {
    target: '',
    // Workaround for https://github.com/davidkpiano/xstate/issues/1198
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    actions: assign<BasketContext, any>({
      errorMessage: undefined,
      warningMessage: undefined,
    }),
  },
};

const basketMachine = Machine<BasketContext, BasketSchema, BasketEvents>({
  id: 'basket',
  initial: 'checkingForAuth',
  states: {
    checkingForAuth: {
      always: [
        {
          target: 'fetchingIncompleteOrder',
          cond: ({ user }) => !!user,
        },
        {
          target: 'idle',
          cond: ({ user }) => !user,
        },
      ],
    },
    fetchingIncompleteOrder: {
      invoke: {
        id: 'fetchingIncompleteOrder',
        src: async ({ user }) => {
          if (!user) {
            throw new Error('Missing user');
          }
          return apolloClient.query({
            fetchPolicy: 'no-cache',
            query: IncompleteOrderDocument,
          });
        },
        onDone: {
          target: 'beforeCreatePaymentPromises',
          actions: assign<
            BasketContext,
            DoneInvokeEvent<ApolloQueryResult<IncompleteOrderQuery>>
          >(({ items, user }, { data: query }) => {
            const order = query.data?.orders[0];

            // No order on the server, storage wins
            if (!order) {
              return {};
            }

            const products = order.items.map(({ product }) =>
              normalizeBasketItem(product),
            );

            // User was signed in? Override their basket
            if (
              !user?.signedInDuringThisSession &&
              !basketItemsAreEqual(items, products)
            ) {
              return {
                items: products.map(normalizeBasketItem),
              };
            }

            // Merge the cart because this user signed in during this session
            const missingBasketItems = products.filter(
              ({ id }) => !items.find((item) => id === item.id),
            );
            if (missingBasketItems.length > 0) {
              return {
                items: uniqBy([...items, ...missingBasketItems], 'id'),
              };
            }
            return {};
          }),
        },
        // TODO log this error
        onError: 'idle',
      },
    },
    beforeCreatePaymentPromises: {
      always: [
        {
          // No user, no payment intent
          target: 'idle',
          cond: ({ user }) => !user,
        },
        {
          // No items
          target: 'idle',
          cond: ({ items }) => items.length === 0,
          // Reset all state to ensure old state not used
          actions: assign<BasketContext, BasketEvents>({
            paymentIntent: undefined,
            paymentIntentItems: undefined,
            paymentIntentPromise: undefined,
            paymentRequest: undefined,
            paymentRequestPromise: undefined,
          }),
        },
        {
          // Don't create payment intent automatically
          target: 'idle',
          cond: ({ userHasInteracted }) => !userHasInteracted,
        },
        {
          // Same items
          target: 'idle',
          cond: ({ items, paymentIntentPromise, paymentIntentItems }) =>
            !!paymentIntentPromise &&
            !!paymentIntentItems &&
            basketItemsAreEqual(items, paymentIntentItems),
        },
        {
          // New items
          target: 'createPaymentPromises',
        },
      ],
    },
    createPaymentPromises: {
      always: 'handlePromises',
      entry: assign<BasketContext, BasketEvents>({
        // Clear previous state immediately to ensure stale payment request or
        // intent never used
        paymentIntent: undefined,
        paymentIntentItems: undefined,
        paymentRequest: undefined,
        paymentIntentPromise: async ({ items }) => {
          try {
            const result = await createPaymentIntent({ products: items });
            return {
              ...result,
              success: true,
            } as CreatePaymentIntentSuccess;
          } catch (error) {
            if (error instanceof ApiError) {
              switch (error.code) {
                case ErrorCodes.AlreadyOwned:
                  return {
                    errorCode: ErrorCodes.AlreadyOwned,
                    purchasedProductIds: (error.graphQLError
                      ?.extensions as AlreadyOwnedError).productIds,
                    success: false,
                  } as CreatePaymentIntentError;
                case ErrorCodes.InvalidProduct:
                  return {
                    errorCode: ErrorCodes.InvalidProduct,
                    success: false,
                  } as CreatePaymentIntentError;
                default:
                  throw error;
              }
            }

            throw error;
          }
        },
        paymentRequestPromise: async ({ items, paymentRequest }) => {
          const stripe = await stripePromise;

          if (!stripe) {
            throw new Error('Stripe not loaded');
          }

          let cachedProducts: BasketProductFragment[] = [];

          try {
            cachedProducts =
              apolloClient.readQuery({
                query: BasketProductsForIdsDocument,
                variables: { ids: items.map(({ id }) => id) },
              })?.products || [];
          } catch {
            // ignore reading from cache error, which shouldn't even happen
          }

          const paymentRequestParams = {
            currency: 'usd',
            displayItems: items.map(({ id, price }, index) => {
              const cachedProduct = cachedProducts.find(
                (product) => product.id === id,
              );
              return {
                amount: price,
                label: cachedProduct ? cachedProduct.name : `Item ${index + 1}`,
              };
            }),
            total: {
              amount: items.reduce((result, { price }) => result + price, 0),
              label: `${items.length} Digital Cross Stitch ${pluralize(
                items.length,
                'Chart',
              )}`,
            },
          };

          let newOrOldPaymentRequest:
            | PaymentRequest
            | undefined = paymentRequest;

          if (newOrOldPaymentRequest) {
            newOrOldPaymentRequest.update(paymentRequestParams);
          } else {
            newOrOldPaymentRequest = stripe.paymentRequest({
              ...paymentRequestParams,
              country: 'US',
              requestPayerName: true,
              requestPayerEmail: true,
            });
          }

          if (await newOrOldPaymentRequest.canMakePayment()) {
            return newOrOldPaymentRequest;
          }
          return null;
        },
      }),
    },
    handlePromises: {
      type: 'parallel',
      on: COMMON_TRANSITIONS,
      states: {
        paymentIntent: {
          initial: 'handlePromise',
          states: {
            handlePromise: {
              invoke: {
                id: 'paymentIntent',
                src: async ({ paymentIntentPromise }) => paymentIntentPromise,
                onDone: {
                  target: 'checkError',
                  actions: [
                    assign<
                      BasketContext,
                      DoneInvokeEvent<CreatePaymentIntentOrError>
                    >(({ userHasInteracted, items }, { data }) => {
                      if (data.success) {
                        return {
                          paymentIntent: data,
                          paymentIntentItems: items,
                        };
                      }
                      switch (data.errorCode) {
                        case ErrorCodes.AlreadyOwned:
                          if (userHasInteracted) {
                            toast('You already own this item.');
                          }
                          return {
                            errorEvent: 'RETRY',
                            items: items.filter(
                              (item) =>
                                !data.purchasedProductIds.includes(item.id),
                            ),
                          };
                        case ErrorCodes.InvalidProduct:
                          return {
                            errorEvent: 'UPDATE_ITEMS',
                            // Only show this warning if they added it during
                            // this session.
                            warningMessage: userHasInteracted
                              ? 'One or more items in your basket changed prices since it was added. Your basket was updated.'
                              : undefined,
                          };
                        default:
                          throw new Error('Invalid error code');
                      }
                    }),
                    ({ items, user }: BasketContext) =>
                      items.length === 0 && user && cancelPaymentIntent(),
                  ],
                },
                onError: {
                  target: 'checkError',
                  actions: assign<BasketContext, DoneInvokeEvent<unknown>>(
                    () => {
                      // TODO track error
                      return {
                        errorEvent: 'ERROR',
                        errorMessage: 'Unknown error',
                      };
                    },
                  ),
                },
              },
            },
            checkError: {
              entry: send((context) => {
                if (!context.errorEvent) {
                  return { type: 'SUCCESS' };
                }
                return { type: context.errorEvent };
              }),
              on: {
                SUCCESS: 'idle',
                ERROR: '#basket.error',
                RETRY: '#basket.beforeCreatePaymentPromises',
                UPDATE_ITEMS: '#basket.updatingItems',
              },
            },
            idle: {},
          },
        },
        paymentRequest: {
          initial: 'handlePromise',
          states: {
            handlePromise: {
              invoke: {
                id: 'handlePaymentRequestPromise',
                src: async ({ paymentRequestPromise }) => paymentRequestPromise,
                onDone: {
                  target: 'idle',
                  actions: assign<
                    BasketContext,
                    DoneInvokeEvent<PaymentRequest | null>
                  >((_context, { data }) => {
                    return {
                      paymentRequest: data || undefined,
                    };
                  }),
                },
                // TODO track error
                onError: 'idle',
              },
            },
            idle: {},
          },
        },
      },
    },
    idle: {
      on: {
        ...COMMON_TRANSITIONS,
        USER_HAS_INTERACTED: {
          target: 'beforeCreatePaymentPromises',
          // Workaround for https://github.com/davidkpiano/xstate/issues/1198
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          actions: assign<BasketContext, any>({
            userHasInteracted: true,
          }),
        },
        SIGNED_IN: {
          target: 'checkingForAuth',
          actions: assign<BasketContext, SignedInEvent>({
            user: (_context, { user }) => user,
          }),
        },
      },
    },
    // Update items from the server because we had a price mismatch
    updatingItems: {
      invoke: {
        id: 'updateItems',
        src: async ({ items }) =>
          apolloClient.query({
            fetchPolicy: 'no-cache',
            query: BasketProductsForIdsDocument,
            variables: { ids: items.map(({ id }) => id) },
          }),
        onDone: {
          target: 'beforeCreatePaymentPromises',
          actions: assign<
            BasketContext,
            DoneInvokeEvent<ApolloQueryResult<BasketProductsForIdsQuery>>
          >(({ user, userHasInteracted }, { data: query }) => {
            const products = query.data?.products || [];
            const productsWithoutFree = products.filter(
              (product) => product.price > 0,
            );

            if (
              userHasInteracted &&
              productsWithoutFree.length !== products.length
            ) {
              toast(
                'Some products in your basket are now free. They were removed.',
              );

              // Cancel the payment intent if this was the last item in the
              // basket, because it won't be canceled above due to this not
              // being caused by a user action.
              if (productsWithoutFree.length === 0 && user) {
                // We're okay not waiting for the result because it doesn't
                // affect the UI.
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                cancelPaymentIntent();
              }
            }

            return { items: productsWithoutFree.map(normalizeBasketItem) };
          }),
        },
      },
    },
    error: {},
  },
});

export default basketMachine;

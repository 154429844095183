import React from 'react';

function IconClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.26916 1.28466C1.56166 0.992062 2.03594 0.992023 2.32849 1.28457L16.8304 15.7862C17.1229 16.0788 17.123 16.5531 16.8305 16.8457C16.538 17.1383 16.0637 17.1383 15.7711 16.8458L1.26924 2.34416C0.976693 2.05161 0.976653 1.57726 1.26916 1.28466Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8303 1.28454C17.1229 1.57704 17.123 2.05132 16.8304 2.34387L2.32877 16.8457C2.03623 17.1383 1.56187 17.1383 1.26928 16.8458C0.976682 16.5533 0.976642 16.079 1.26919 15.7865L15.7708 1.28463C16.0634 0.992073 16.5377 0.992034 16.8303 1.28454Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconClose;
